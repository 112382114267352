.page-body .material {
  display: flex;
}
.page-body .material .material-wechat {
  width: 370px;
  padding: 0 30px;
  margin-right: 20px;
}
.page-body .material .material-wechat .wechat-box {
  width: 310px;
  background-color: #ffffff;
  box-shadow: 0 0 8px #e5e5e580;
  min-height: 500px;
}
.page-body .material .material-wechat .wechat-box .wechat-name {
  padding: 10px 15px;
}
.page-body .material .material-wechat .wechat-box .cover-box {
  height: 130px;
  overflow: hidden;
  position: relative;
  border: 2px solid #ffffff;
  cursor: pointer;
}
.page-body .material .material-wechat .wechat-box .cover-box .cover-title {
  position: absolute;
  left: 15px;
  right: 15px;
  bottom: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  color: #fff;
  font-weight: 400;
  z-index: 1;
}
.page-body .material .material-wechat .wechat-box .active {
  border: 2px solid #07c160;
}
.page-body .material .material-wechat .wechat-box .content-list .item {
  padding: 12px;
  cursor: pointer;
}
.page-body .material .material-wechat .wechat-box .content-list .item .item-title {
  padding-right: 20px;
  color: #353535;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  font-weight: 400;
  z-index: 1;
  margin: 8px 0;
}
.page-body .material .material-wechat .wechat-box .content-list .active {
  border: 2px solid #07c160;
}
.page-body .material .material-wechat .wechat-box .content-list .add-item {
  padding: 30px 0;
  text-align: center;
  color: #9a9a9a;
  cursor: pointer;
}
.page-body .material .material-wechat .wechat-box .content-list .add-item .add-item-txt {
  margin-left: 10px;
}
.page-body .material .material-editor {
  background-color: #fff;
  flex-grow: 1;
  padding: 20px;
}
.page-body .submit-bar {
  text-align: center;
  padding: 40px 0;
}
.page-body .submit-bar .submit-save-btn {
  background-color: #07c160;
  border-color: #07c160;
}
.page-body .submit-bar .submit-save-btn:hover {
  background-color: #06ad56;
}
.page-body .submit-bar .submit-preview {
  margin-left: 20px;
  padding: 0 30px;
}
.page-body .submit-bar .submit-preview:hover {
  background-color: #e7e7eb;
  border-color: #dadbe0;
}
